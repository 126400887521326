import React, { useCallback, useEffect } from 'react';
import Countdown, { CountdownTimeDelta } from 'react-countdown';
import { useSelector } from 'react-redux';

// UI Kit
import CheckMarkIcon from 'ui-kit/icons/check-mark-icon';
import Spinner from 'ui-kit/spinner/spinner';

// Components
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';

// Pages
import {
    FailureUpdateProfileModalContent,
    VerifySmsRequestCanceled,
    VerifySmsRequestSuccess
} from 'pages/secure/profile/intra-page-items/_profile-update-modal.item';

// States
import { resetSmsVerification } from 'state/account/account.reducers';
import {
    accountCancelSmsRequestRoutine,
    accountCheckSmsRequestsStatusRoutine,
    accountUpdateNotificationsRoutine
} from 'state/account/account.routines';
import {
    accountSmsCancelingReqeustSelector,
    accountSmsCheckingStatusSelector,
    accountSmsPendingRequestsSelector,
    accountSmsRequestInitiatedSelector,
    accountSmsRequestsNotVerifiedSelector,
    accountSmsRequestsPendingSelector,
    accountSmsRequestsVerifiedSelector
} from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';

import { SMS_OPT_IN_TIMER, SMS_POLL_MAX_TIME_SECONDS } from 'const/account';

import { getPhoneNumber } from 'util/globalVariables';
// Utils
import { addZero } from 'util/number';
import { maskPhoneNumber } from 'util/string';

// Styles and Types
import './index.style.scss';
import { ConfirmationStatuses, VerifySmsModalProps } from './types';

export const VerifySmsModal: React.FC<VerifySmsModalProps> = ({
    t,
    dispatch,
    valuesToSubmit,
    resetContactPreferences
}) => {
    const pendingRequests = useSelector(accountSmsPendingRequestsSelector);
    const accountSmsRequestsVerified = useSelector(accountSmsRequestsVerifiedSelector);
    const checkingSmsStatus = useSelector(accountSmsCheckingStatusSelector);
    const smsRequestsPending = useSelector(accountSmsRequestsPendingSelector);
    const smsRequestInitiated = useSelector(accountSmsRequestInitiatedSelector);
    const smsRequestsNotVerified = useSelector(accountSmsRequestsNotVerifiedSelector);
    const smsCancelingReqeust = useSelector(accountSmsCancelingReqeustSelector);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!checkingSmsStatus && smsRequestsPending) {
                dispatch(accountCheckSmsRequestsStatusRoutine.trigger({}));
            }
        }, SMS_POLL_MAX_TIME_SECONDS);

        return () => clearInterval(interval);
    }, [checkingSmsStatus, dispatch, smsRequestsPending]);

    const handleCancelSmsRequests = useCallback(() => {
        if (!smsCancelingReqeust) {
            dispatch(
                accountCancelSmsRequestRoutine.trigger({
                    onSuccess: () => {
                        resetContactPreferences();
                        dispatch(resetSmsVerification({}));
                        dispatch(
                            openModal({
                                showClose: true,
                                bodyContent: <VerifySmsRequestCanceled />,
                                ctas: [
                                    {
                                        label: t('modals.updateProfile.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        },
                                        dataGALocation: 'VerifySMSModalUpdateProfile'
                                    }
                                ]
                            })
                        );
                    }
                })
            );
        }
    }, [dispatch, resetContactPreferences, smsCancelingReqeust, t]);

    useEffect(() => {
        if (smsRequestsNotVerified) {
            handleCancelSmsRequests();
        }
    }, [handleCancelSmsRequests, smsRequestsNotVerified]);

    useEffect(() => {
        if (accountSmsRequestsVerified) {
            dispatch(
                accountUpdateNotificationsRoutine.trigger({
                    ...valuesToSubmit,
                    onSuccess: () => {
                        dispatch(closeModal({}));
                        dispatch(
                            openModal({
                                showClose: true,
                                bodyContent: <VerifySmsRequestSuccess />,
                                ctas: [
                                    {
                                        label: t('modals.updateProfile.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        },
                                        dataGALocation: 'VerifySMSModalUpdateProfile'
                                    }
                                ]
                            })
                        );
                        dispatch(resetSmsVerification({}));
                        resetContactPreferences();
                    },
                    onFailure: () => {
                        dispatch(closeModal({}));
                        dispatch(
                            openModal({
                                showClose: true,
                                size: 'lg',
                                type: 'danger',
                                headerContent: (
                                    <BirdiModalHeaderDanger icon="alert" headerText={t('modals.updateProfile.error')} />
                                ),
                                bodyContent: (
                                    <FailureUpdateProfileModalContent
                                        area={t('modals.updateProfile.areas.notifications')}
                                    />
                                ),
                                ctas: [
                                    {
                                        label: t('modals.updateProfile.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        },
                                        dataGALocation: 'ContactPreferenceUpdateProfileError'
                                    }
                                ]
                            })
                        );
                        dispatch(resetSmsVerification({}));
                    }
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountSmsRequestsVerified]);

    const renderer = ({ minutes, seconds, completed }: CountdownTimeDelta) => {
        if (completed) {
            handleCancelSmsRequests();
            return null;
        } else {
            // Render a countdown
            return (
                <h2 className="m-0">
                    {minutes}:{addZero(seconds)}
                </h2>
            );
        }
    };

    return (
        <div className="verify-sms-container">
            <h2>{t('modals.smsVerification.title')}</h2>
            <div className="spacer align-self-center" />

            <div className="verify-sms-message mt-2">
                <span>{t('modals.smsVerification.messageSentTo')}</span>
            </div>
            <div className="verify-sms-pending-requests">
                {pendingRequests.map((smsRequest, index) => {
                    return (
                        <div className="verify-sms-pending-request" key={index}>
                            <span className="sms-request-number">{maskPhoneNumber(smsRequest.PhoneNumber)} </span>
                            {smsRequest.status === ConfirmationStatuses.Confirmed ? (
                                <CheckMarkIcon />
                            ) : (
                                <Spinner
                                    isVisible={true}
                                    t={t}
                                    fullOverlay={false}
                                    isSmall={true}
                                    className="primary-grey-spinner"
                                />
                            )}
                        </div>
                    );
                })}

                <div className="verify-sms-message">
                    <span>{t('modals.smsVerification.verifyYourNumberWithin')}</span>
                </div>
            </div>

            <div className="verify-sms-message">
                <Countdown renderer={renderer} date={smsRequestInitiated + SMS_OPT_IN_TIMER} />
            </div>

            <div className="verify-sms-message">
                <span>
                    {t('modals.smsVerification.didntReceiveMessage', {
                        phoneNumber: getPhoneNumber({ isPlainText: true })
                    })}
                </span>
            </div>

            <div className="verify-sms-message cancel-disclaimer mb-0">
                <span>{t('modals.smsVerification.selectCancelMessage')}</span>
            </div>
        </div>
    );
};
